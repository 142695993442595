<template lang="pug">
div
  transition(name="slide")
    l-control.control-container.bgLeafletControl(
      class="series-dialog-wrapper"
      v-show="dialog"
      position="bottomleft")
      div(
        class="series-dialog-header" v-if="seriesPnt")
        | Resultado gráfica series de tiempo para ( {{seriesPnt.lat}} | {{seriesPnt.lng}} )
        v-btn(class="float-right pb-2" icon dark @click="closeDialog")
          v-icon mdi-close
      //- bar-loader(v-if="loadingGraph")
      div(class="d-flex")
        v-switch(
          class="mt-0"
          @click="lineGraph = !lineGraph"
          color="orange darken-3"
          title="Cambiar tipo de grafico")
        span(class="mt-2") Graficar con {{lineGraph === true ? 'barras' : 'líneas' }}
      div(class="box-chart")
          forecast-line-chart(
            v-show="lineGraph === true"
            :graphLabels="netCDFPeriods"
            :variables="[]"
            style="height:250px"
            class="chart-container")
          forecast-bar-chart(
            v-show="lineGraph === false"
            :graphLabels="netCDFPeriods"
            :variables="[]"
            style="height:250px"
            class="chart-container")
  l-marker(
    v-if="selectedPnt"
    :lat-lng="selectedPnt"
    :icon="icon"
    @ready="markerMixinReady"
    ref="mixinMarker")
    //- BOX EN MODO RESPONSIVE PARA DESKTOP
  div(v-if="selectorVisible")
    l-control.control-container.bgLeafletControl.hidden-md-and-down(
      :class="{width: showTip === true ? 'map-control-opened-lg' : 'map-control-closed'}"
      position="topright" id="map-control"
    )
      .control-icon.py-1.px-1(
        v-if="!showTip" @click="toggleTip" style="cursor: pointer" title="Mapas y series"
      )
        v-icon(large) mdi-earth-box
      .control-tip.hidden-md-and-down(
        v-else class="py-4 px-4 mx-1" id="box-large"
      )
        .text-h5.mb-1
          | Mapas y Series
          v-btn(
            icon
            small variant="text" elevation="0"
            density="comfortable" class="text-center float-right pa-2 my-1" @click="cancel"
          )
            v-icon(large) mdi-chevron-double-right mdi-28px
        div( class="consulta-rapida bgLeafletControl")
          v-row
            v-col(cols="12" md="12" sm="12" xs="12" class="pb-0 pl-6")
              v-card-title(class="consulta-title") Consultas rápidas
            v-col(cols="12" md="12" sm="12" xs="12" class="d-flex justify-start pt-0 pb-4")
                v-btn(
                  icon
                  small
                  variant="text" elevation="2"
                  class="bgLeafletControl boton-rapido ml-5 mr-3" @click="quickButton('T2')"
                  title="Temperatura a 2m"
                )
                  v-icon mdi-thermometer mdi-24px
                v-btn(
                  icon
                  small
                  variant="text" elevation="2"
                  class="bgLeafletControl boton-rapido ml-3 mr-3" @click="quickButton('ACPRECST')"
                  title="Precipitación"
                )
                  v-icon mdi-weather-pouring mdi-24px
                v-btn(
                  icon
                  small
                  variant="text" elevation="2"
                  class="bgLeafletControl boton-rapido ml-3 mr-3"
                  @click="quickButton('CG_FLASHCOUNT')"
                  title="Rayos"
                )
                  v-icon mdi-weather-lightning mdi-24px
                v-btn(
                  icon
                  small
                  variant="text" elevation="2"
                  class="bgLeafletControl boton-rapido ml-3 mr-3" @click="quickButton('UUVV10')"
                  title="Viento a 10m"
                )
                  v-icon mdi-weather-windy mdi-24px
                v-btn(
                  icon
                  small
                  variant="text" elevation="2"
                  class="bgLeafletControl boton-rapido ml-3 mr-5" @click="quickButton('CLDFRA')"
                  title="Nubosidad"
                )
                  v-icon mdi-clouds mdi-24px
        v-divider(v-if="selectedPnt")
          .my-2(v-if="selectedPnt")
            div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
              | Punto seleccionado
            div {{selectedPnt.lat}} | {{selectedPnt.lng}}
            div(v-if="rasterVal")
              v-icon(small) mdi-information-outline
              span
                | {{selectedVariable.name}}: <b>{{rasterVal}}</b> {{selectedVariable.unit}}
        v-divider
        div(class="loading-container" v-if="loadingData")
          bar-loader
        .my-2(style="zoom:0.9")
          v-spacer(style="height:5px")
          forecast-variables(
            ref="variablesControl" @trigger="generateSeries"
          )
          color-ramp-picker(class="pb-2 control-internal-content" v-model="currentRamp")
          v-row
            v-col(cols="12" md="6" sm="6" xs="6" class="pr-0 pt-4")
              v-switch(v-model="showParticles" label="Partículas" small)
            v-col(cols="12" md="6" sm="6" xs="6" class="ma-0 pa-1 py-8")
              v-btn(
                v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
                rounded
                class="text-none text-subtitle-1 premium-button-lg"
                variant="elevated"
                color="white"
                title="Obtener versión paga"
                @click="goPayment"
                outlined)
                v-icon mdi-medal
                <b>Plan Premium</b>
        v-divider
        span(v-if="errorMsg" class="mt-2 control-error errorLefletControl--text") {{errorMsg}}
        .mt-2.d-flex.control-internal-content(style="zoom:0.9")
          v-row
            v-col(cols="12" md="6" sm="6" xs="6")
              v-btn(
                block
                :loading="disabledButton2"
                :disabled="disabledButton2"
                height="35px"
                color="#00B0FF"
                class="text-button"
                @click="loadVaribleLayer") Mapa
                  v-icon(right dark small) mdi-map-outline mdi-18px
            v-col(cols="12" md="6" sm="6" xs="6")
              v-tooltip(left)  Seleccione un punto en el mapa para generar una serie de tiempo
                template(v-slot:activator="{ on }")
                  v-btn(
                    block
                    height="35px"
                    :loading="disabledButton"
                    :disabled="disabledButton"
                    color="#F9A825"
                    class="text-button"
                    @click="generateSeries" v-on="on") Serie
                      v-icon(right dark small) mdi-chart-areaspline mdi-18px
    //- BOX EN MODO RESPONSIVE PARA MOVILES
  div(v-if="selectorVisible")
    l-control.control-container.bgLeafletControl.hidden-lg-and-up(
      :class="{width: showTip === true ? 'map-control-opened-sm' : 'map-control-closed'}"
      position="bottomright" id="map-control")
      .control-icon.py-1.px-1(
        v-if="!showTip",
        @click="toggleTip",
        style="cursor: pointer"
        title="Mapas y series"
      )
        v-icon(large) mdi-earth-box
      .control-tip.scrollable.hidden-lg-and-up(v-else class="py-4 px-4 mx-1" id="box-small")
        .text-h6.mb-1
          | Mapas y series
          v-btn(
            icon
            small
            variant="text"
            elevation="0"
            density="comfortable"
            class="text-center float-right"
            @click="cancel")
            v-icon(large) mdi-chevron-double-down mdi-24px
        div( class="consulta-rapida-small")
          v-row
            v-col(cols="12" md="12" sm="12" xs="12" class="pb-0 pl-2")
              v-card-title(class="consulta-title") Consultas rápidas
            v-col(cols="12" md="12" sm="12" xs="12" class="d-flex justify-start pt-0 pb-4")
              v-btn.mr-2(
                icon small variant="text" elevation="2"
                class="bgLeafletControl boton-rapido-small"
                @click="quickButton('T2')"
                title="Temperatura a 2m"
              )
                v-icon mdi-thermometer mdi-18px
              v-btn.mr-2.ml-2(
                icon small variant="text" elevation="2"
                class="bgLeafletControl boton-rapido-small"
                @click="quickButton('ACPRECST')"
                title="Precipitación"
              )
                v-icon mdi-weather-pouring mdi-18px
              v-btn.mr-2.ml-2(
                icon small variant="text" elevation="2"
                class="bgLeafletControl boton-rapido-small"
                @click="quickButton('CG_FLASHCOUNT')"
                title="Rayos"
              )
                v-icon mdi-weather-lightning mdi-18px
              v-btn.mr-2.ml-2(
                icon small variant="text" elevation="2"
                class="bgLeafletControl boton-rapido-small"
                @click="quickButton('UUVV10')"
                title="Viento a 10m"
              )
                v-icon mdi-weather-windy mdi-18px
              v-btn.ml-2(
                icon small variant="text" elevation="2"
                class="bgLeafletControl boton-rapido-small"
                @click="quickButton('CLDFRA')"
                title="Nubosidad"
              )
                v-icon mdi-clouds mdi-18px
        v-divider(v-if="selectedPnt")
        .my-2(v-if="selectedPnt")
          div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
            | Punto seleccionado
          div {{selectedPnt.lat}} | {{selectedPnt.lng}}
          div(v-if="rasterVal")
            v-icon(small) mdi-information-outline
            span
              | {{selectedVariable.name}}: <b>{{rasterVal}}</b> {{selectedVariable.unit}}
        v-divider
        div(class="loading-container" v-if="loadingData")
          bar-loader
        .my-2
          v-spacer(style="height:5px")
          forecast-variables(ref="variablesControl")
          color-ramp-picker(class="pb-2 control-internal-content" v-model="currentRamp")
          v-row
            v-col(cols="12" sm="12" xs="12" class="pr-0 pt-1 pb-0")
              v-switch(v-model="showParticles" label="Partículas" small class="particulas-switch")
            v-col(cols="12" sm="12" xs="12" class="py-5 pt-0")
              v-btn(
                v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
                rounded
                class="text-none text-subtitle-2 premium-button-sm"
                variant="elevated"
                color="white"
                title="Obtener versión paga"
                @click="goPayment"
                outlined)
                v-icon mdi-medal mdi-18px
                <b>Plan Premium</b>
        v-divider
        span(v-if="errorMsg" class="mt-2 control-error errorLefletControl--text") {{errorMsg}}
        .mt-2.d-flex.control-internal-content
          v-row
            v-col(cols="12" sm="6" xs="6")
              v-btn(
                :loading="disabledButton2"
                :disabled="disabledButton2"
                block
                small
                color="#00B0FF"
                class="text-body"
                @click="loadVaribleLayer") Mapa
                v-icon(right dark small) mdi-map-outline
            v-col.pl-1(cols="12" sm="6" xs="6")
              v-btn.ml-0(
                block
                :loading="disabledButton"
                :disabled="disabledButton"
                small
                color="#F9A825"
                class="text-body"
                @click="generateSeries") Serie
                v-icon(right dark small) mdi-chart-areaspline
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
// import * as d3 from 'd3';
import * as chroma from 'chroma-js';
import moment from 'moment';
import L, { icon, map } from 'leaflet';
import { fromArrayBuffer } from 'geotiff';

import { mapState, mapActions, mapMutations } from 'vuex';
import {
  LMarker,
  LControl,
} from 'vue2-leaflet';
import ForecastLineChart from '@/components/Forecast/ForecastLineChart.vue';
import ForecastBarChart from '@/components/Forecast/ForecastBarChart.vue';
import markerMixin from '@/mixins/markerMixin.js';
import lfControlMixin from '@/mixins/lfControlMixin.js';
import BarLoader from '@/components/ui/BarLoader.vue';
import ColorRampPicker from '@/components/ui/ColorRampPicker';
import LfStaticMapFrame from '@/components/Leaflet/LfStaticMapFrame.vue';
import ForecastVariables from '@/components/Forecast/ForecastVariables.vue';
import {
  tiffServerUrl,
  serverApi,
  xWindComponent,
  yWindComponent,
  initialColorRamp,
  particlesColorRamp,
  frontendRoute,
  rampLabelFontSize,
} from '@/config.js';
import { getToken } from '@/utils.js';

export default {
  name: 'Maps',
  components: {
    LMarker,
    LControl,
    ColorRampPicker,
    BarLoader,
    LfStaticMapFrame,
    ForecastLineChart,
    ForecastVariables,
    ForecastBarChart,
  },
  mixins: [markerMixin, lfControlMixin],
  data() {
    return {
      icon: icon({
        iconUrl: require('@/assets/marker-icon.png'),
        shadowUrl: require('@/assets/marker-shadow.png'),
        iconSize: [25, 37],
        iconAnchor: [16, 37]
      }),
      rasterVal: null,
      loadingData: true,
      currentVariableLayer: null,
      scaleControl: null,
      variableRange: null,
      scaleParticlesControl: null,
      particlesLayer: null,
      showParticles: true,
      errorMsg: '',
      selectedPnt: null,
      mixinMarker: null,
      currentRamp: initialColorRamp,
      particlesRamp: particlesColorRamp,
      dialog: false,
      lineGraph: true,
      disabledButton: false,
      disabledButton2: false,
      seriesPnt: null,
      showTip: true,
      customSelectedVariable: 'ACPRECST',
      selectorVisible: true,
    };
  },
  computed: {
    ...mapState('ui/leaflet', ['netCDFPeriods', 'selectedVariable',
      'selectedPeriod', 'selectedLevel', 'netCDFVariables']),
    ...mapState('gis', ['appModules']),
  },
  watch: {
    showParticles(newValue) {
      // ocultar capa de particulas
      this.selectedPnt = null;
      this.rasterVal = null;
      if (this.particlesLayer) {
        if (newValue) {
          this.$parent.$parent.map.addLayer(this.particlesLayer);
        } else {
          this.$parent.$parent.map.removeLayer(this.particlesLayer);
        }
      }
    },
    async $route(to) {
      // remover las capas canvas si la url siguiente apunta a la aplicacion "maps"
      if (to.query.appType === 'maps') {
        this.removeCanvasLayers();
        // cargar las variables NETCDF parala nueva aplicación
        await this.getAppParams(to.params.app);
        // obtener la avariable inicial de acuerdo a la aplicación
        await this.SET_INITIAL_VARIABLE();
        // una vez las variables netcdf hayan cargado volver a cargar capas canvas
        this.loadCanvasLayers();
      }
      this.$parent.$parent.map.removeControl(this.scaleParticlesControl);
    },
    currentRamp(newValue) {
      console.log('Colorramp change', newValue, this.variableRange);
      console.log(this.scaleControl);
      // cambiar el color de la rampa de la variable cuando hay un cambio en el control
      const scale = chroma.scale(newValue).domain(this.variableRange);
      this.currentVariableLayer.setColor(scale);

      // remover rampa anterior
      this.$parent.$parent.map.removeControl(this.scaleControl);

      // creacion del control y agregar nueva rampa
      const bar = this.$canvasLf.control.colorBar(scale, this.variableRange, {
        title: `${this.selectedVariable.name} (${this.selectedVariable.unit})`,
        units: this.selectedVariable.unit,
        steps: 100,
        decimals: 1,
        width: 200,
        height: 10,
        position: 'bottomleft',
        background: '#fff',
        textColor: 'black',
        textLabels: this.variableRange.map((x) => x.toFixed(3)),
        labels: this.variableRange,
        labelFontSize: rampLabelFontSize
      }).addTo(this.$parent.$parent.map);
      this.scaleControl = bar;
    }
  },
  beforeDestroy() {
    // Eliminar las capas canvas cuando se cambia de aplicacion
    this.removeCanvasLayers();
  },
  mounted() {
    // obtener la variable inicial de acuerdo a la aplicación
    this.SET_INITIAL_VARIABLE();
    // obtener la aplicación actual a través de la URL
    if (this.$route.query.appType === 'maps') {
      this.quickButton(this.customSelectedVariable, true);
    }
    // crear las capas canvas una vez el componenete este montado
    this.loadCanvasLayers();
  },
  methods: {
    ...mapActions('ui/leaflet', ['getAppParams']),
    ...mapMutations('ui/leaflet', ['SET_INITIAL_VARIABLE']),
    ...mapMutations('meteocolombia', ['SET_LOADING_CANVAS']),
    closeDialog() {
      this.dialog = false;
      this.selectorVisible = true;
    },
    quickButton(variableName, firstLoad = false) {
      // seleccionar la variable en el select
      // this.customSelectedVariable = variableName;
      this.$store.dispatch('ui/leaflet/setNetCDFState', {
        stateName: 'selectedVariable',
        stateValue: variableName
      });
      if (!firstLoad) {
        this.loadVaribleLayer();
      }
    },
    async loadCanvasLayers() {
      // Actualizar el periodo requerido
      this.$store.dispatch('ui/leaflet/setNetCDFState', {
        stateName: 'selectedPeriod',
        stateValue: moment().format('YYYY-MM-DD HH:00')
      });

      // indicar que los datos del mapa estan cargando
      this.loadingData = true;

      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }

      // obtener la componente X de la velocidad del viento
      let response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.mapas}?variable=${xWindComponent}&date=${this.selectedPeriod}`, {
        headers
      });
      let jsonWindU = '';
      if (response.status === 200) {
        const jsonResp = await response.json();
        jsonWindU = `${tiffServerUrl}${jsonResp.url}`;
      } else {
        this.loadingData = false;
        this.errorMsg = 'Error al cargar la componente U de la velocidad del viento';
        return;
      }

      // obtener la componente Y de la velocidad del viento
      response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.mapas}?variable=${yWindComponent}&date=${this.selectedPeriod}`, {
        headers
      });
      let jsonWindV = '';
      if (response.status === 200) {
        const jsonResp = await response.json();
        jsonWindV = `${tiffServerUrl}${jsonResp.url}`;
      } else {
        this.loadingData = false;
        this.errorMsg = 'Error al cargar la componente V de la velocidad del viento';
        return;
      }

      // obtener capa inicial que se desplegara como raster
      response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.mapas}?variable=${this.selectedVariable.variable}&date=${this.selectedPeriod}`, {
        headers
      });
      let jsonInitialVar = '';
      if (response.status === 200) {
        const jsonResp = await response.json();
        jsonInitialVar = `${tiffServerUrl}${jsonResp.url}`;
      } else {
        this.loadingData = false;
        this.errorMsg = `Error al cargar la información de ${this.selectedVariable.name}`;
        return;
      }

      // los datos del mapa han cargado
      this.loadingData = false;

      // cargar los datos para las consulatas NETCDF
      this.loadGeotiffVariable(jsonInitialVar);
      this.loadGeotiffParticles(jsonWindU, jsonWindV);
    },
    removeCanvasLayers() {
      // remover la barra de la escala del mapa
      if (this.scaleControl) {
        this.$parent.$parent.map.removeControl(this.scaleControl);
      }
      // remover las particulas
      if (this.showParticles === true && this.particlesLayer) {
        this.$parent.$parent.map.removeLayer(this.particlesLayer);
      }
      // remover el mapa de la variable
      this.$parent.$parent.map.removeLayer(this.currentVariableLayer);
    },
    async generateSeries() {
      // limpiar el error anterior
      this.errorMsg = '';
      if (!this.selectedPnt) {
        this.errorMsg = 'Debe seleccionar un punto en el mapa';
        return;
      }

      if (!this.selectedLevel) {
        if (this.selectedVariable.type3D) {
          this.errorMsg = 'Debe seleccionar un nivel para consultar';
          return;
        }
      }

      // validar variables extra del modulo
      if (!this.$refs.variablesControl.validate()) {
        this.errorMsg = 'Hay variables que contienen errores';
        return;
      }

      this.loadingGraph = true;
      // dehabilitar el boton de las series
      this.disabledButton = true;
      // se obtinen los datos geoJSON de la polylinea
      const coords = this.mixinMarker.toGeoJSON().geometry.coordinates;

      let querys = `?lat=${coords[1]}&lon=${coords[0]}&variable=${this.selectedVariable.variable}`;
      if (this.selectedVariable.type3D) {
        querys += `&level=${this.selectedLevel}`;
      }
      // traer los valores de las variables extra si existen
      const extraParams = this.$refs.variablesControl.urlQueryParams();
      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }
      // hacer peticion para cargar datos de la serie
      const response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.series}${querys}${extraParams}`, {
        headers
      });
      if (response.status === 200) {
        const jsonResp = await response.json();
        // asignar el punto de la serie
        this.seriesPnt = this.selectedPnt;
        this.$bus.$emit('add-line-dataset', {
          labels: this.netCDFPeriods,
          data: jsonResp.data,
          fill: false,
          title: `${this.selectedVariable.name} [${this.selectedVariable.unit}]`
        });
        this.$bus.$emit('add-bar-dataset', {
          labels: this.netCDFPeriods,
          data: jsonResp.data,
          fill: false,
          title: `${this.selectedVariable.name} [${this.selectedVariable.unit}]`
        });
        this.dialog = true;
        this.selectorVisible = false;
      } else {
        this.loadingGraph = false;
        this.errorMsg = 'Error al cargar la información de la serie';
      }
      // habilitar el boton de las series una vez haya terminado
      this.disabledButton = false;
    },
    lefletMapReady() {
      // una vez el mapa este listo desactivar el zoom con el raton sobre el control
      const elem = L.DomUtil.get('map-control');
      L.DomEvent.on(elem, 'mousewheel', L.DomEvent.stopPropagation);
    },
    async loadVaribleLayer() {
      // Coloca el mensaje cargando en el mapa
      this.SET_LOADING_CANVAS(true);
      // limpiar el mensaje de error
      this.errorMsg = '';
      // validar variables extra del modulo
      if (!this.$refs.variablesControl.validate()) {
        this.errorMsg = 'Hay variables que contienen errores';
        return;
      }

      if (this.loadingData) {
        this.errorMsg = 'Debe esperar a que los datos carguen';
        return;
      }

      // deshablitar el boton del control
      this.disabledButton2 = true;

      // indicar que los datos del mapa estan cargando
      this.loadingData = true;

      if (this.currentVariableLayer) {
        this.$parent.$parent.map.removeLayer(this.currentVariableLayer);
      }
      let querys = `variable=${this.selectedVariable.variable}&date=${this.selectedPeriod}`;
      if (this.selectedVariable.type3D) {
        querys += `&level=${this.selectedLevel}`;
      }
      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.mapas}?${querys}`, {
        headers
      });
      let jsonVar = '';
      const jsonResp = await response.json();
      if (response.status === 200) {
        jsonVar = `${tiffServerUrl}${jsonResp.url}`;
        this.loadGeotiffVariable(jsonVar);
      } else {
        this.errorMsg = `Error al cargar la información de ${this.selectedVariable.name} - (${jsonResp || response.statusText})`;
      }

      // los datos del mapa han cargado
      this.loadingData = false;
      // habilitar el botón del control una vez la petición haya terminado
      this.disabledButton2 = false;
      // Retira el mensaje cargando en el mapa
      this.SET_LOADING_CANVAS(false);
    },
    async loadGeotiffParticles(windDirection, windSpeed) {
      const mainThis = this;
      let u = null;
      let v = null;
      d3.request(windDirection)
        .responseType('arraybuffer')
        .get((error, tiffData) => {
          if (error) {
            console.info('Falló la carga de la dirección del viento');
            return;
          }
          u = tiffData.response;
          d3.request(windSpeed)
            .responseType('arraybuffer')
            .get((error, tiffData) => {
              if (error) {
                console.info('Falló la carga de la velocidad del viento');
                return;
              }
              v = tiffData.response;
              const vf = mainThis.$canvasLf.VectorField.fromGeoTIFFs(u, v);
              const { range } = vf;
              const scale = chroma.scale(mainThis.particlesRamp).domain(range);
              const layer = new mainThis.$canvasLf.CanvasLayer.VectorFieldAnim(vf, {
                paths: 5000,
                color: scale,
                velocityScale: 1 / 200
              }).addTo(mainThis.$parent.$parent.map);

              layer.on('click', (e) => {
                if (e.value !== null) {
                  const vector = e.value;
                  const v = vector.magnitude().toFixed(2);
                  const d = vector.directionTo().toFixed(0);
                  const html = (`<span class="popupText">${v} m/s a ${d}&deg</span>`);
                  const popup = mainThis.$canvasLf.popup()
                    .setLatLng(e.latlng)
                    .setContent(html);
                    // .openOn(mainThis.$parent.$parent.map);
                }
              });
              mainThis.particlesLayer = layer;
            });
        });
    },
    async loadGeotiffVariable(resource) {
      const mainThis = this;
      this.selectedPnt = null;
      this.rasterVal = null;
      const test = await d3.text(resource);
      d3.request(resource).responseType('arraybuffer').get(
        (error, tiffData) => {
          if (error) {
            console.info('Falló la carga de la variable seleccionada');
            return;
          }
          const s = mainThis.$canvasLf.ScalarField.fromGeoTIFF(tiffData.response);
          const { range } = s;
          const scale = chroma.scale(mainThis.currentRamp).domain(range);
          const layer = new mainThis.$canvasLf.canvasLayer.scalarField(s, {
            color: scale, // RdPu
            opacity: 0.65
          }).addTo(mainThis.$parent.$parent.map);

          if (mainThis.scaleControl) {
            mainThis.$parent.$parent.map.removeControl(mainThis.scaleControl);
          }

          mainThis.variableRange = range;

          // creacion del control para etiquetar la escala del mapa
          const bar = mainThis.$canvasLf.control.colorBar(scale, range, {
            title: `${mainThis.selectedVariable.name} (${mainThis.selectedVariable.unit})`,
            units: mainThis.selectedVariable.unit,
            steps: 100,
            decimals: 1,
            width: 200,
            height: 10,
            position: 'bottomleft',
            background: '#fff',
            textColor: 'black',
            textLabels: range.map((x) => x.toFixed(3)),
            labels: range,
            labelFontSize: rampLabelFontSize
          }).addTo(mainThis.$parent.$parent.map);

          layer.on('click', (e) => {
            let v = e.value;
            if (e.value !== null) {
              v = v.toFixed(3);
            }
            mainThis.rasterVal = v;
          });
          mainThis.currentVariableLayer = layer;
          mainThis.scaleControl = bar;
          if (mainThis.showParticles === true && mainThis.particlesLayer) {
            mainThis.$parent.$parent.map.removeLayer(mainThis.particlesLayer);
            mainThis.$parent.$parent.map.addLayer(mainThis.particlesLayer);
          }
        }
      );
    },
    cancel() {
      this.showTip = !this.showTip;
      this.selectedPnt = null;
      this.errorMsg = '';
    },
    createRose() {
      if (!this.selectedPnt) {
        this.errorMsg = 'Debe seleccionar un punto en el mapa';
        return;
      }

      this.cancel();

      // se obtinen los datos geoJSON de la polylinea
      console.log(this.mixinMarker.toGeoJSON());
    },
    mapClick(e) {
      if (this.showTip) {
        const lat = e.latlng.lat.toFixed(6);
        const lng = e.latlng.lng.toFixed(6);
        this.selectedPnt = {
          lat,
          lng,
        };
      }
    },
  },
};
</script>

<style scoped>
.loading-container {
  position: absolute;
  z-index: 200;
  background: rgba(212, 212, 212, 0.6);
}
@media (min-width: 800px) {
  .loading-container {
    height: 265px;
    width: 291px;
    padding-top: 105px;
  }
}
@media (max-width: 799px) {
  .loading-container {
    width: 179px;
    height: 240px;
    padding-top: 80px;
  }
}

/*.scrollable {
  overflow-y: scroll;
  overflow-x: visible;
  max-height: 100%;
}
.control-internal-content, .control-tip, .box-large {
  width: 320px!important;
}
.control-internal-content, .control-tip, .box-small {
  width: 280px!important;
}*/

#map-control .map-control-opened-lg {
  width: 320px!important;
}
#map-control .map-control-opened-sm {
  width: 280px!important;
}

.v-application .my-2 {
  margin-top: 7px !important;
  margin-bottom: 0px !important;
}

.row .v-input__control, .v-messages {
  display: none !important;
}
.boton-rapido {
  background-color: #BBDEFB;
  border: 1px solid #BDBDBD;
  height: 35px;
  width: 35px;
}

.boton-rapido-small {
  background-color: #BBDEFB;
  border: 1px solid #BDBDBD;
}
.boton-rapido-small .v-icon {
}

.consulta-rapida {
  position: absolute;
  top: -82px;
  right: 0px;
  z-index: 1000;
  cursor: pointer;
  background-color: #ffffff;
  padding: 3px;
  border: 2px solid #BDBDBD;
  border-radius: 15px;
  width: 328px;
}
.consulta-rapida-small {
  position: absolute;
  top: -60px;
  right: 0px;
  z-index: 1000;
  cursor: pointer;
  padding: 3px;
  border-radius: 15px;
  width: 211px;
}
.consulta-title {
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  padding: 7px 5px 6px;
  font-family: 'Roboto', sans-serif;
}
.leaflet-bar {
  border-radius: 14px !important;
}
</style>
