<template lang="pug">
div(class="text-center mt-3")
  span {{title}}
  div(class="d-flex" style="height: 410px;")
    div(
      id="svgContainer"
      style="padding-left:80px;"
    )
  div(
    v-if="dataLoaded"
    id="vertical-ramp"
    style="position:absolute; top:69px; right:80px; z-index: 1002;"
  )
    vertical-ramp(:min-val="minVal" :max-val="maxVal")
</template>
<script>
import * as d3 from 'd3';
import VerticalRamp from '@/components/ui/VerticalRamp.vue';

export default {
  name: 'ContourCut',
  components: {
    VerticalRamp,
  },
  data(instance) {
    const contourData = instance.$attrs.contourData || [];
    return {
      dataLoaded: false,
      height: 300,
      minVal: 0,
      maxVal: 0,
      data: [],
      coords: [],
      title: '',
      contourData,
    };
  },
  computed: {
    realWidth() {
      return window.innerWidth;
    }
  },
  created() {
    window.addEventListener('resize', this.windowResizeHandler);
    this.$bus.$on('contour-data-loader', (data) => {
      this.data = data.data;
      this.coords = data.coords;
      this.title = data.title;
      this.drawContours();
    });
  },
  mounted() {
    if (this.contourData) {
      this.data = this.contourData.data;
      this.coords = this.contourData.coords;
      this.title = this.contourData.title;
      this.drawContours();
    }
  },
  destroyed() {
    // window.removeEventListener('resize', this.windowResizeHandler);
    console.log('destroyed');
  },
  methods: {
    drawContours() {
      this.dataLoaded = false;
      // obtener valor minimo y maximo
      const combinedArray = [];
      for (let i = 0; i < this.data.length; i++) {
        const element = this.data[i];
        for (let j = 0; j < element.length; j++) {
          const item = element[j];
          combinedArray.push(item);
        }
      }
      this.minVal = Math.min(...combinedArray);
      this.maxVal = Math.max(...combinedArray);
      this.dataLoaded = true;

      this.createContours(window.innerWidth);
    },
    windowResizeHandler() {
      // this.createContours(window.innerWidth);
      console.log('window.innerWidth:', window.innerWidth);
      document.getElementById('bodydiv').style.width = `${window.innerWidth - 95}px`;
      document.getElementById('bodydiv').scrollLeft = 0;
    },
    createContours(_newWidth) {
      let newWidth = _newWidth;
      if (_newWidth % 2 !== 0) {
        newWidth += 1;
      }
      const me = this;
      const y = d3.scaleLinear([0, 45000], [this.height, 0]);

      // const lats = this.coords.map((x) => x[0]);
      const dists = this.coords.map((x) => x[2]);
      const formatCoords = this.coords.map((x) => `${x[0].toFixed(2)}, ${x[1].toFixed(2)}`);
      const resol = 3 * window.devicePixelRatio;
      const x = d3.scaleOrdinal()
        .domain(this.coords)
        .range(this.coords.map((x, i) => this.coords.length * dists[i] * resol * 10));

      const yAxis = (g) => g
        .attr('transform', 'translate(-1,0)')
        .call(d3.axisLeft(y))
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick').filter((d) => y.domain().includes(d)).remove());

      const xAxisGenerator = d3.axisBottom(x)
        .tickValues(formatCoords)
        .tickPadding(35)
        .tickFormat((d, i) => formatCoords[i]);

      const xAxis = (g) => g
        .attr('transform', 'translate(0,300)')
        // .attr('transform', 'rotate(-90)')
        .attr('class', 'x-axis')
        .attr('width', 500)
        .call(xAxisGenerator);

      const createGrid = function () {
        const q = 3; // The level of detail, e.g., sample every 4 pixels in x and y.

        const n = me.coords.length;
        const m = me.data.length;
        const grid = new Array(n * m);
        const realData = me.data.reverse();
        for (let j = 0; j < m; ++j) {
          for (let i = 0; i < n; ++i) {
            grid[j * n + i] = realData[j][i];
          }
        }
        grid.x = -q;
        grid.y = -q;
        grid.ky = q;
        grid.kx = resol * 10;
        grid.n = n;
        grid.m = m;
        return grid;
      };

      const grid = createGrid();

      const transform = ({ type, value, coordinates }) => ({
        type,
        value,
        coordinates: coordinates.map((rings) => rings.map((points) => points.map(([x, y]) => ([
          grid.x + grid.kx * x, // grid.x + grid.k * x,
          grid.y + grid.ky * y
        ]))))
      });

      // d3.interpolateMagma d3.interpolateRdBu
      const colorExtend = d3.extent(grid);
      let contours = [];
      let color = null;
      if (colorExtend[0] === colorExtend[1] && colorExtend[1] !== 0) {
        color = d3.scaleSequential([0, colorExtend[0]], d3.interpolateViridis);
        contours = d3.contours()
          .size([grid.n, grid.m])
          .thresholds([colorExtend[0]])(grid)
          .map(transform);
      } else if (colorExtend[1] === 0) {
        color = d3.scaleSequential([0, 1], d3.interpolateViridis);
        this.maxVal = 1;
        contours = d3.contours()
          .size([grid.n, grid.m])
          .thresholds([colorExtend[0]])(grid)
          .map(transform);
      } else {
        color = d3.scaleSequential(d3.extent(grid), d3.interpolateViridis);
        contours = d3.contours()
          .size([grid.n, grid.m])(grid)
          .map(transform);
      }

      const chart = function () {
        const parent = d3.select('#svgContainer');
        // Borrar el contenido del contenedor
        parent.selectAll('*').remove();

        // Eje Y
        const svgY = parent.append('svg')
          .attr('id', 'svgContourYaxis')
          .attr('height', me.height + 85)
          .style('left', '12px')
          .style('position', 'absolute')
          .style('z-index', 1000)
          .style('width', '100%');

        // agregar al elemento svg la etiqueta del eje de la presión
        svgY.append('text')
          .attr('class', 'y label')
          .attr('text-anchor', 'end')
          .attr('x', -90)
          .attr('y', 0)
          .attr('dy', '12px')
          .attr('transform', 'rotate(-90)')
          .text('Altura [pies]');

        svgY.append('g')
          .call(yAxis)
          .attr('transform', 'translate(70, 0)');

        svgY.selectAll('.label').style('font-size', '12px');

        // Eje X
        const divX = parent.append('div')
          .style('overflow-y', 'hidden')
          .style('overflow-x', 'scroll')
          .style('touch-action', 'pan-x pan-y')
          .style('-webkit-overflow-scrolling', 'touch')
          .style('position', 'absolute')
          .style('bottom', '0px')
          .style('text-align', 'left')
          .style('width', 'calc(100% - 165px)')
          .style('scrollbar-color', '#1976d2 #BDBDBD');

        const svgX = divX.append('svg')
          .attr('id', 'svgContourXaxis')
          .style('z-index', 1000)
          // .style('width', `${this.coords.length / (newWidth / 50)}px`)
          .style('width', `${me.coords.length * 38}px`)
          .style('height', '388px')
          .style('left', '100px');

        svgX.append('g')
          // .style('overflow', 'visible')
          .attr('fill', 'none')
          .attr('stroke', 'black')
          .attr('stroke-opacity', 0.5)
          .style('height', '300px')
          .selectAll('path')
          .data(contours)
          .join('path')
          .attr('fill', (d) => color(d.value))
          .attr('d', d3.geoPath());

        svgX.append('g')
          .call(xAxis)
          .attr('transform', 'translate(1, 300)')
          .style('width', `${newWidth + 1}px`);

        // agregar al elemento svg la etiqueta del eje de las coordenadas
        svgX.append('text')
          .attr('class', 'x label')
          .attr('text-anchor', 'end')
          .attr('x', newWidth / 2)
          .attr('y', 385)
          .style('color', 'black')
          .text('Latitud, Longitud');

        // rotar los ticks del eje de las coordenadas
        d3.selectAll('.x-axis .tick text').attr('transform', 'rotate(-90) translate(-37,-42)');

        // console.log('svg width:', svg.attr('width'));

        return parent.node();
      };

      // eslint-disable-next-line
      const el = chart();
      const styleWidth = `${newWidth - 95}px`;
      console.log('styleWidth:', styleWidth);
      // document.getElementById('bodydiv').style.width = styleWidth;
      // document.getElementById('bodydiv').scrollLeft = 0;
    },
  }
};
</script>

<style scoped>
.y-axis {
  transform: rotate(90deg);
  height: min-content;
  margin-top: 175px;
}
</style>
